import React,{useRef,useState} from 'react'
import PageLoader from '../../../components/partners/basic/pageLoader'
import wallpaper from "../../../assets/images/login/3.jpg"
import logo from "../../../assets/images/site_logo.png"
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLoginMutation } from '../../../features/auth/authApiSlice'
import { setCredentials } from '../../../features/auth/authSlice'

function LoginPage() {

    const navigate = useNavigate();
    const formref=useRef(null)
    const[login,{isLoading}]=useLoginMutation()
    const dispatch = useDispatch()
    const[incorrectdetails,setIncorectDetails]=useState(false)


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      };
      
    
    const handleAlertClose=()=>{
      setIncorectDetails(false)
    }
    
    const [formData, setFormData] = useState({
     
      email: "",
      'password':"",
      
      
    
    });




    const handleLogin=async (e)=>{
  
        e.preventDefault()
            const isValid = formref.current.checkValidity(); // Check if all form elements are valid
        
            console.log(isValid);
            
            
        
            if (isValid) {

                try {
          
          
          
                  const signinData= await login({"email":formData.email,'password':formData.password}).unwrap()
                  console.log(signinData)
                  if (signinData.is_number_verified===false){
                    dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_number_verified:signinData.is_number_verified}))
          
                    navigate("/partner/verify")
                  }
                  else{
                    dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_number_verified:signinData.is_number_verified}))
                    navigate("/partner")
                  }
                
                formData.email=""
                formData.password=""
                }
                  
                    catch(e){
                      console.log(e)
                      if ( e.status===401){
          setIncorectDetails(true)
                      }
                    }
                
              }
              else{
          
                formref.current.classList.add("was-validated")
          
          
              }
          
          }
          
        
        
        
    

  return (
    <>
<PageLoader/>
<section className="sign-in-page bg-white">
            <div className="container-fluid p-0">
                <div className="row no-gutters">
                    <div className="col-sm-6 align-self-center">
                        <div className="sign-in-from ">
                        <div className='w-full d-flex justify-content-center mb-2'>
                        <img src={logo} className="img-fluid" alt="logo" style={{height:"70px",width:"200px"}} />
                        </div>
                        <div className='w-full text-center'>
                            <h4 className="mb-0 ">Sign in</h4>
                            <p>Enter your email address and password to access partners panel.</p>
</div>
                            
                            {
                              

  incorrectdetails?          <div className="alert text-white bg-danger" role="alert">
                              <div className="iq-alert-text">Invalid username or password!</div>
                              <button type="button"  onClick={handleAlertClose} className="close" data-dismiss="alert" aria-label="Close">
                              <i className="ri-close-line"></i>
                              </button>
                           </div> :""
}
                            <form  ref={formref}  onSubmit={handleLogin} className="mt-2 needs-validation  " noValidate>
                                <div >
                                    <label>Email address</label>
                                    <input required type="email" value={formData.email}   onChange={handleChange}  name='email' className="form-control mb-0" id="exampleInputEmail1" placeholder="Enter email"/>
                                </div>
                                <div>
                                    <label >Password</label>
                                    <a href="#" className="float-right">Forgot password?</a>
                                    <input   required type="password" className="form-control mb-0"   name="password"   onChange={handleChange}  value={formData.password}    id="exampleInputPassword1" placeholder="Password" minLength="8" />
                                </div>
                                <div className="d-inline-block w-100 mt-3">
                                    <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1" >
                                        <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                                        <label className="custom-control-label" >Remember Me</label>
                                    </div>
                                    {
                                        isLoading? 
                                        <button type="submit" className="btn btn-primary float-right">
                                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Signing in...</button>:
                                    <button type="submit" className="btn btn-primary float-right">Sign in</button>


                                    }
                                   
                                </div>
                                <div className="sign-info">
                                    
                                    <ul className="iq-social-media">
                                        <li><a href="#"><i className="ri-facebook-box-line"></i></a></li>
                                        <li><a href="#"><i className="ri-twitter-line"></i></a></li>
                                        <li><a href="#"><i className="ri-instagram-line"></i></a></li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-sm-6 text-center">
                        

                    <div className="sign-in-detail text-white" style={{ position: 'relative', overflow: 'hidden' }}>
    <div
        
    />
    <div
        className="background-image"
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `url(${wallpaper}) no-repeat center center`,
            backgroundSize: 'cover',
            zIndex: 0
        }}
    />
    <div className="content" style={{ position: 'relative', zIndex: 2 }}>
        <a className="sign-in-logo mb-5" href="#"></a>
        <div className="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
            <div className="item">
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
            </div>
            <div className="item">
                <img src="images/login/1.png" className="img-fluid mb-4" alt="logo" />
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
            </div>
            <div className="item">
                <img src="images/login/1.png" className="img-fluid mb-4" alt="logo" />
                <h4 className="mb-1 text-white">Manage your orders</h4>
                <p>It is a long established fact that a reader will be distracted by the readable content.</p>
            </div>
        </div>
    </div>
</div>


                    </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default LoginPage