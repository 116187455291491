import React from 'react'
import Sidebar from '../../../components/partners/basic/sidebar'
import { FilePond, registerPlugin } from 'react-filepond';
import Navbar from '../../../components/partners/basic/navbar'
import { Link } from 'react-router-dom'

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

function Settings() {
  return (
    <>
    <Navbar/>
       <Sidebar />
      
       <div className="content-page px-2  " id="content-page">
   


                          <div class="container">

                          <nav aria-label="breadcrumb">
                              <ol class="breadcrumb bg-primary mb-0">
                                 <li class="breadcrumb-item" style={{marginTop:"5px"}}><Link   to='/partner' class="text-white " ><i class="ri-home-4-line mr-1 float-left "></i> <span >Dasboard </span> </Link></li>
                                 <li class="breadcrumb-item" > </li> <li style={{marginTop:"5px"}} ><Link to='/partner/settings' href="#" class="text-white"  style={{marginTop:"5px"}} >Settings</Link></li>
                               
                              </ol>
                           </nav>

                          <div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Settings</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <form className='row '>
                              <div class=" col-md-6">
                                 <label for="colFormLabelSm">First Name </label>
                                 <input type="email" class="form-control form-control-sm" id="colFormLabelSm"/>
                              </div>

                              <div class=" col-md-6">
                                 <label for="colFormLabelSm">Last Name </label>
                                 <input type="email" class="form-control form-control-sm" id="colFormLabelSm" />
                              </div>

                              <div class=" col-md-6">
                                 <label for="colFormLabelSm">Email</label>
                                 <input type="email" class="form-control form-control-sm" id="colFormLabelSm" />
                              </div>
                             
                              <div class=" col-md-6">
                                 <label for="colFormLabelSm">Phone Number</label>
                                 <input type="email" class="form-control form-control-sm" id="colFormLabelSm" />
                              </div>
                              <div class=" col-md-6">
                                 <label for="colFormLabelSm">Password</label>
                                 <input type="email" class="form-control form-control-sm" id="colFormLabelSm" />
                              </div>
                              <div class=" col-md-6">
                                 <label for="colFormLabelSm">Confirm Password</label>
                                 <input type="email" class="form-control form-control-sm" id="colFormLabelSm" />
                              </div>
                              <div class=" col-md-6 mt-2">
                              <label for="colFormLabelSm">Logo/profile picture</label>

                              <FilePond
               
                allowMultiple={false}
                disabled
                name="avatar_url"
                stylePanelLayout="compact"
                styleButtonRemoveItemPosition="left"
                styleButtonProcessItemPosition="right"
                styleLoadIndicatorPosition="right"
                styleProgressIndicatorPosition="right"
                styleButtonRemoveItemAlign={false}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
              />
                              </div>

                              <div className=' d-flex justify-content-start mt-4 border-t '>
                              <button className='btn btn-success me-2'>Save</button>
                              <button className='btn btn-primary '>Update</button>


                              </div>
                           </form>
                        </div>
                     </div>
             
                          </div>
                          </div>
                          </>
  )
}

export default Settings