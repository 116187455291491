import React from 'react'
import Sidebar from '../../../components/partners/basic/sidebar'

import Navbar from '../../../components/partners/basic/navbar'
import { Link } from 'react-router-dom'

function Promo_Codes() {
  return (
    <>
    <Navbar/>
       <Sidebar />
      
       <div className="content-page px-2  " id="content-page">
   


                          <div class="container">

                          <nav aria-label="breadcrumb">
                              <ol class="breadcrumb bg-primary mb-0">
                                 <li class="breadcrumb-item" style={{marginTop:"5px"}}><Link   to='/partner' class="text-white " ><i class="ri-home-4-line mr-1 float-left "></i> <span >Dasboard </span> </Link></li>
                                 <li class="breadcrumb-item" > </li> <li style={{marginTop:"5px"}} ><Link to='/partner/promo_codes' href="#" class="text-white"  style={{marginTop:"5px"}} >Promo Codes</Link></li>
                               
                              </ol>
                           </nav>
                          <div className='row mt-3'>
                   
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                        <button  type="button" class="btn btn-primary  btn-block  "><i class="ri-check-double-line"></i> Active Promo Codes</button>
                        
                    

                        </div>

                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                        <button  type="button" class="btn btn-primary  btn-block  "><i class="ri-close-circle-fill"></i>  Expired Promo Codes</button>
                        
                    

                        </div>


                  </div>

                         <div className='row mt-5'>



<div className='col-12'>
<div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Promo_Codes List</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <table class="table">
                              <thead>
                                 <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Date Joined</th>
                                   
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <th scope="row">Mark Anthony</th>
                                    <td>12/45/67</td>
                                    
                                 </tr>

                                 <tr>
                                    <th scope="row">Mark Anthony</th>
                                    <td>12/45/67</td>
                                    
                                 </tr>
                                
                              
                              </tbody>
                           </table>
                        </div>
                     </div>
</div>


                       

                         </div>
                          </div>
                          </div>
                          </>
  )
}

export default Promo_Codes