import { apiSlice } from "../../app/api/apiSlice";


export const partnersApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        partnerDashboard:builder.mutation({
            query:()=>({
                url:'/partner/dashboard/ ',
                method: 'GET',

            })
        }),   
        userReceipts:builder.mutation({
            query:()=>({
                url:'/transaction/partner-receipts/',
                method: 'GET',

            })
        }),  
        
         

        

    



    })


})
  



export const {
usePartnerDashboardMutation,
useUserReceiptsMutation

}=partnersApiSlice;




















