import React,{useEffect, useState} from 'react'
import Sidebar from '../../../components/partners/basic/sidebar'

import Navbar from '../../../components/partners/basic/navbar'
import { Link } from 'react-router-dom'
import image from "../../../assets/images/page-img/01.jpg"
import { usePartnerDashboardMutation } from '../../../features/partners/partnersSlice'
import Skeleton from 'react-loading-skeleton'


function Dashboard() {
  const [getPartnerDashboard,{isLoading}] = usePartnerDashboardMutation()
  const [dashboardData,setDashboardData]=useState()


  const handleGetDasboardData=async()=>{
    try{
      const response= await getPartnerDashboard().unwrap()
      console.log(response)
      setDashboardData(response)

    }
    catch(error){
      console.error(error)
    }
  }

  useEffect(()=>{
    handleGetDasboardData()
  },[])

  return (
    <>
     <Navbar/>
        <Sidebar />
       
        <div className="content-page px-2  " id="content-page">
       



                           <div class="container">
                           <nav aria-label="breadcrumb ">
                              <ol class="breadcrumb bg-primary mb-0">
                                 <li class="breadcrumb-item"><Link  to="/partner" href="#" class="text-white"><i class="ri-home-4-line mr-1 float-left"></i>Dashboard</Link></li>
                              </ol>
                           </nav>
                     <div class="row mt-3">
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-1 text-primary">
                             
                           
                              <div class="iq-card-body">
                                <div class=""> 
                                   <h5>Today's Referrals</h5>
                                   <h4>{dashboardData&&dashboardData.todays_refereal}</h4>
                                 </div>
                             </div>

                            
                          </div>
                        }
                       </div>
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-2 text-warning">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>This Week's Referrals</h5>
                                   <h4>{dashboardData&&dashboardData.week_refereal}</h4>
                                 </div>
                             </div>
                          </div>
                          }
                       </div>
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-3 text-danger">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>Total Referrals (This Month)</h5>
                                   <h4>{dashboardData&&dashboardData.month_refereal}</h4>
                                 </div>
                             </div>
                          </div>
                       }
                       </div>
                     
                     
                      
                       
                       
                       
                      </div>

                      <div class="row mt-3">
                       <div class="col-md-6 col-lg-4">
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-1 text-primary">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>Today's Commision</h5>
                                   <h4>GH₵ 8</h4>
                                 </div>
                             </div>
                          </div>
                       </div>
                       <div class="col-md-6 col-lg-4">
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-2 text-warning">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>This Week's Commision</h5>

                                   <h4>GH₵ 8,0000</h4>
                                 </div>
                             </div>
                          </div>
                       </div>
                       <div class="col-md-6 col-lg-4">
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-3 text-danger">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>This Month's Commsion</h5>
                                   <h4>GH₵ 90,0000</h4>

                                 </div>
                             </div>
                          </div>
                       </div>
                     
                     
                      
                       
                       
                       
                      </div>

                      <div className='row mt-3'>
                     
                      
                      <div className="iq-card"  style={{overflowX:"scroll"}} >
  <div className="iq-card-header d-flex justify-content-between">
    <div className="iq-header-title">
      <h4 className="card-title">Current Promo Detail</h4>
    </div>
    <div className="iq-card-header-toolbar d-flex align-items-center">
      <div className="dropdown">
        <span
          className="dropdown-toggle"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="ri-more-2-fill"></i>
        </span>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <a className="dropdown-item" href="#">
            <i className="ri-eye-fill mr-2"></i>View
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-delete-bin-6-fill mr-2"></i>Delete
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-pencil-fill mr-2"></i>Edit
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-printer-fill mr-2"></i>Print
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-file-download-fill mr-2"></i>Download
          </a>
        </div>
      </div>
    </div>
  </div>
  <div className="iq-card-body" style={{ minWidth: "1000px", overflowX: "scroll" }}>
    <div className="row" >
      <div className="col-4">
        <div className="media-sellers">
          <div className="media-sellers-img">
            <img src={image} className="mr-3 rounded" alt="" />
          </div>
          <div className="media-sellers-media-info d-flex align-items-center">
            <h5 className="mb-0 mt-4">Kwadwo Sheldon</h5>
          </div>
        </div>
      </div>
      <div className="col-2 text-center mt-3">
        <h6>Code </h6>
        <h5 className="mb-0 mt-1">Dingo</h5>
      </div>
      <div className="col-2 text-center mt-3">
        <h6>Total Referrals </h6>
        <h5 className="mt-1">800</h5>
      </div>
      <div className="col-3 text-center mt-3">
        <h6>Days Left </h6>
        <h5 className="mt-1">800</h5>
      </div>
    </div>
  </div>
</div>

                      </div>
                  </div>
        </div>

    
    </>
  )
}

export default Dashboard