import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetPayoutListMutation } from '../../features/transaction/transactionSlice'
import { useWithdrawalHostMutation } from '../../features/transaction/transactionSlice'
import "../../asserts/css/theme.min.css"


function PayOutHistory() {
    const [getpayoutlist,{isLoading}]=useGetPayoutListMutation();
    const [payoutList,setPayoutList]=useState()
    const [withdraw,{isLoading:isWithdrawing}]=useWithdrawalHostMutation()

    const handlegetpayoutlist=async()=>{
        try{
           const response = await getpayoutlist()
           setPayoutList(response.results);
           console.log(response)

        }catch(error){
            console.log(error)
        }
    }


    useEffect(()=>{
        handlegetpayoutlist()
    },[])



  return (


  <div class="col-lg-8 col-md-7 mb-5 ">

				<div class="card card card-body border-0 shadow-sm pb-1 me-lg-1">
					<div class="pt-2">
						<label class="form-label fw-bold mb-3">Payout History</label>
						<Link to='/dashboard/payout-form' >Request Payout Statement</Link>
					</div>

										<table class="table ">
						<thead>
							<tr>
								<th scope="col">Payout No</th>
								<th scope="col">Date</th>
								<th scope="col">Time</th>
								<th scope="col">Amount</th>
								<th scope="col">Status</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
															<tr>
									<td colspan="5" class="text-center">No Payouts at the moment!</td>
								</tr>
													</tbody>
					</table>
				</div>
				

			</div>
  
  )
}

export default PayOutHistory