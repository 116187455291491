import React,{useState,useEffect} from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { useGetPropertiesDetailUserMutation,useGetBoookingDetailMutation } from '../../features/property/propertySlice';
import { useInitiateTransactionMutation } from '../../features/transaction/transactionSlice';
import Skeleton from 'react-loading-skeleton';
import { useParams,useNavigate } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';
import { Link } from 'react-router-dom';



function PropertyBookingPaymentDetail() {
  const navigate = useNavigate();

  const [getpropertydetail,{isLoading}]=useGetPropertiesDetailUserMutation()
  const [bookingDetail,{isLoading:isBookingLoading}]=useGetBoookingDetailMutation()
  const [initiatetransaction,{isLoading:transactionLoading}]=useInitiateTransactionMutation()

  const [propertydetail,setPropertyDetail]=useState()
  const [booking,setBooking]=useState()
  const [duration,setDuration]=useState()
  const [totaldaysprice,setTotaldaysPrice]=useState()
  const { id } = useParams();
  const [images,setImages]=useState()
  


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  const handleInitiateTransaction =async()=>{

    try {
  
      const response=await initiatetransaction({
        "booking_id":booking.id,
        "callback_url":window.location.href,
    }).unwrap();
      console.log(response)
   
      window.location.href = response.payment_url;
  
    }catch(e){
      console.log(e)
    }
  }

  function formatDate(dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZoneName: 'short'
    };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  }



  function calculateDurationInDays(checkIn, checkOut) {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
    const durationInMilliseconds = checkOutDate - checkInDate;
    const durationInDays = durationInMilliseconds / (1000 * 60 * 60 * 24);
    return durationInDays;
  }




  const handlegetbookingdetail=async() => {
    try{
      

      const response = await bookingDetail(id).unwrap();
      console.log(response,"book")
      setBooking(response)
      setDuration(calculateDurationInDays(response.check_in, response.check_out))
      setTotaldaysPrice(parseInt(calculateDurationInDays(response.check_in, response.check_out)*response.price.price))
      const response2=await getpropertydetail(response.property.id);
      console.log('detail',response2.data);
      setPropertyDetail(response2.data)
      const filesArray = response2.data.other_files.map(item => item.files);
      setImages([response2.data.files,...filesArray])
     

    }
    catch(e){
      console.log(e)
    }
  }

  useEffect(()=>{
    

    handlegetbookingdetail();
   
  },[])


  function calculateServicePrice(service, durationInDays) {
    
    let totalCost = 0;
  
    if (durationInDays >= 30) {
      totalCost = service.charge_per_month
    }
    if (durationInDays >= 7) {
      totalCost = service.charge_per_week
   
    }
    if (durationInDays > 0 && durationInDays<7) {
      totalCost = service.charge_per_day 
    }
  
    return totalCost;
  }
  


function calculateDiscountedPrice(propertyDetails, durationInDays) {
 
  let discount = 0;

  if (durationInDays >= 30) {
    discount = propertyDetails.discount_one_month;
  } else if (durationInDays >= 14) {
    discount = propertyDetails.discount_two_week;
  } else if (durationInDays >= 7) {
    discount = propertyDetails.discount_one_week;
  }


  return discount;
}

const CustomArrow = ({ type, onClick, isEdge }) => {
  const isPrevArrow = type === 'PREV';
  const isNextArrow = type === 'NEXT';

  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '24px'
      }}
    >
      {isPrevArrow ? <i class="fi-chevron-left"></i> : <i class="fi-chevron-right"></i>}
    </button>
  );
};

  return (
    <div class="container mt-5 mb-md-4 py-5">
       
        <nav class="mb-3 pt-2 pt-lg-3" aria-label="Breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="city-guide-home-v1.html">Home</a></li>
            <li class="breadcrumb-item"><a href="city-guide-add-business.html">Property</a></li>
            <li class="breadcrumb-item active" aria-current="page">Payment</li>
          </ol>
        </nav>
        
        <h3 class="h3 pb-2 mb-4"></h3>
     
        <section class="container mb-5 pb-2 mb-5">
        <div class="row align-items-center justify-content-center">

{
  
}    
          <div class="col-lg-4 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
            <h3 class="mb-4">{propertydetail&&propertydetail.title}</h3>
            <p class="mb-4 pb-3 fs-lg">{propertydetail&&propertydetail.description}</p><Link to='/help' class="btn btn-lg btn-primary" href="real-estate-contacts.html">Contact us</Link>
          </div>
        
          <div class="col-lg-7 col-md-6 offset-md-1 col-12 order-md-2 order-1">
           
       
          <Carousel
    
     itemsToShow={1}
    itemsToScroll={1}
    showArrows={true}
    renderArrow={CustomArrow}
    enableAutoPlay={false}
    autoPlaySpeed={1500}
    infinite={true}
    pagination={true}
    disableArrowsOnEnd={true}
    focusOnSelect={true}
   
  
       >


{
  images&&images.map((item,index)=>(
    <div style={{height:"400px",width:"100%"}} key={index} >
    <img  className="rounded rounded-md-3"  src={`https://dev-api.shortstaybuddy.com${item}`} alt={propertydetail&&propertydetail.title} style={{width:"100%",height:"100%",objectFit:"cover"}} />
    </div>
  ))
}



    
</Carousel>
          </div>
        </div>
      </section>
        <span className='d-block mb-2'><span className='h4 '>{propertydetail&&propertydetail.pricing[0].currency} {propertydetail&&propertydetail.pricing[0].price}</span><small>/per day</small> <br/>
        <span>{booking&&formatDate(booking.check_in)}-{booking&&formatDate(booking.check_out)}</span>
        </span>

        <h2 class="h3 pt-2 pt-md-3 mb-4">Payment Breakdown</h2>
        <div class="card card-hover card-body px-4 mb-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="ad-option-1" checked />
            <label class="form-check-label d-sm-flex align-items-center justify-content-between" for="ad-option-1">
            <span class="d-block px-1">
            <span class="d-block h6 mb-2">Price Per day </span>
            <span class="d-block mb-2 mb-sm-0">total price of   <strong>{duration&&duration} days </strong> booked</span>
            </span>
            <span class="d-block  mb-0">
            <span class="h4">{propertydetail&&propertydetail.pricing[0].currency} {totaldaysprice&&totaldaysprice}</span>
           </span></label>
          </div>
        </div>

        {
          propertydetail&&propertydetail.service_fee.map((service_fee,index)=>{
            const cost = calculateServicePrice(service_fee, duration&&duration);
            return (
              <div class="card card-hover card-body px-4 mb-2">
          <div class="form-check">
            <input class="form-check-input" checked type="checkbox" id="ad-option-2"/>
            <label class="form-check-label d-sm-flex align-items-center justify-content-between" for="ad-option-2">
            <span class="d-block px-1"><span class="d-block h6 mb-2">Service Charge </span>
            <span class="d-block mb-2 mb-sm-0">{service_fee.service}</span>
            
            </span>
            
            <span class="d-block h6 mb-0">
            
            {propertydetail&&propertydetail.pricing[0].currency} {cost}</span></label>
          </div>
        </div>
            )
          })
        }
        
        <div class="card card-hover card-body px-4 mb-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="ad-option-3"/>
            <label class="form-check-label d-sm-flex align-items-center justify-content-between" for="ad-option-3">
            <span class="d-block px-1"><span class="d-block h6 mb-2">Discount Applied </span>
            <span class="d-block mb-2 mb-sm-0">discount given by host for <strong>{duration&&duration} days</strong> booking   </span>
            </span><span class="d-block h6 text-danger mb-0">-${propertydetail&&duration&&calculateDiscountedPrice(propertydetail.pricing[0], duration)}</span></label>
          </div>
        </div>
        <div class="text-end pt-4">
          <div class="h4 mb-4">Total:{propertydetail&&propertydetail.pricing[0].currency} {booking&&booking.total_amount}</div>
          <button onClick={() => navigate(-1)} class="btn btn-outline-secondary btn-lg rounded-pill me-2" type="button">Cancel</button>
          {
            transactionLoading?
            <button class="btn btn-primary btn-lg rounded-pill" type="button" ><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Loading...</button>

            :
            <button class="btn btn-primary btn-lg rounded-pill" type="button" onClick={handleInitiateTransaction}>Make Payment</button>


          }
        </div>
      </div>
  )
}

export default PropertyBookingPaymentDetail