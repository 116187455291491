import React, { useEffect, useRef,useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sandboxGhana from "../../asserts/img/city-guide/home/Sandbox_Ghana.gif"
import upcoming1 from '../../asserts/img/city-guide/home/upcoming-1.jpeg'
import upcoming3 from '../../asserts/img/city-guide/home/upcoming-3.jpg'
import "../../asserts/css/carousel.css"
import { Link ,useNavigate} from 'react-router-dom';
import { useGetEventMutation } from '../../features/property/eventSlice'; 
import Skeleton from 'react-loading-skeleton';
function InterestingPlaces() {

  const [getevents,{isLoading}]=useGetEventMutation()

  const [events,setEvents]=useState()
  const slider = useRef();
  const navigate=useNavigate()

  
const handleGetEvents=async()=>{


  const response = await getevents()

  setEvents(response.data.results)
  console.log(response.data.results)
  
  
  }


  const handleNavigate=(id,slug)=>{
    localStorage.setItem('event_id',id)
    navigate(`/events/detail/${slug} `)
}

  useEffect(()=>{
    handleGetEvents()

  },[])

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe:true,
        touchMove:true,
        centerMode:true,
        centerPadding:"150px",
        responsive:[
          {
            breakpoint: 767.98, // At max-width of 768px
            settings: {
              dots: true,
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
              swipe:true,
              touchMove:true,
              centerMode:false,
              
            }
        },
        ]
       
    }

    
  return (
    <section className="container mb-5 pb-lg-3">
  <div class="d-flex align-items-center   justify-content-between mb-4 pb-md-2">
				<h2 class="h3 w-100 mb-md-0">Experiences</h2>
			
			</div>

      {
        isLoading?
        <Skeleton style={{width:"100%",height:"300px"}}/>
        :
        <Slider className='slider2' {...settings} ref={slider} >


    {
    
      events 
      &&
      events.slice(0, 3).map((event,index)=>{

        const startDate = new Date(event.start_date);
  const endDate = new Date(event.end_date);
        return (

<div>

<div     style={{backgroundImage: `url(${event.featured_image})`, backgroundSize: 'cover',marginLeft:"20px" ,height:"358px"}} class="card bg-size-cover bg-position-center border-0 overflow-hidden"  >
  <span class="img-gradient-overlay"></span>
  <div class="card-body content-overlay pb-0">
    <span class={`badge ${event.event_type==='active'?"bg-primary":"bg-danger"} fs-sm`}>{event.event_type}</span>
  </div>
  <div class="card-footer content-overlay border-0 pt-0 pb-4">
    <div class="d-sm-flex justify-content-between align-items-end pt-5 mt-2 mt-sm-5">
      <Link to={`/events/detail/${event.id}`}   href="#" class="text-decoration-none text-light pe-2">
        <div class="fs-sm text-uppercase pt-2 mb-1"></div>
        <h3 class="h5 text-light mb-1">{event.title}</h3>
        <div class="fs-sm opacity-70">
          <i class="fi-map-pin me-1"></i>
          {event.location}
        </div>
      </Link>
      <div class="btn-group ms-n2 ms-sm-0 mt-3">
        <span class="btn btn-primary px-3">₵{event.price}</span>
        <button type="button" class="btn btn-primary btn-icon border-end-0 border-top-0 border-bottom-0 border-light fs-sm">
          <i class="fi-heart"></i>
        </button>
      </div>
    </div>
  </div>
</div>

</div>




      
        )

      },[])
    }
      
   

   
        
        </Slider>
      }

        
     <div className='d-flex justify-content-end  '>
     <Link to='/experiences' class="btn btn-link fw-normal ms-md-3 pb-0" >
					View all
					<i class="fi-arrow-long-right ms-2"></i>
				</Link>

     </div>
  </section>
  )
}

export default InterestingPlaces